import React from "react"
import { Routes } from "../../data/routes"
import { Subjects } from "../../data/subjects"
import { Link } from "gatsby"
import CTA from "../../components/CTA"
import Img from "../../components/img"
import MathResourceDrawer from "../../components/MathResourceDrawer"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Content = {
  pageName: "Math Resources",
  header: "Math resources for every student",
  cover: "math-resources-cover.jpeg",
  coverAltText: "Student looking at smartphone",
  description: `Yup is here to support you on your math journey. Our tutors are
    experts in all of the subjects below. Click into each subject to
    learn more about specific concepts.`,

  // Subjects section
  subjects: Subjects,

  // CTA section
  cta: "Our tutors are standing by 24/7 to support you",
  ctaActions: [
    {
      link: "/",
      text: "Get started",
    },
  ],
}

function Header() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/heroes#component-6364cb4174b8dfdfbd7fa71ac72ab862
  return (
    <div className="relative">
      <div className="absolute inset-x-0 bottom-0 h-1/2" />
      <div className="sm:p-6 lg:p-8">
        <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
          <div className="absolute inset-0">
            <Img
              className="h-full w-full"
              src={Content.cover}
              alt={Content.coverAltText}
              cover={true}
            />
          </div>
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
              <span className="block text-white">{Content.header}</span>
            </h1>
            <p className="mt-6 max-w-lg mx-auto text-center text-xl text-gray-100 sm:max-w-3xl">
              {Content.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

function SubjectsSection() {
  // Original TailwindUI component: https://tailwindui.com/components/application-ui/lists/grid-lists#component-a26a744b444974a4cc73cb5886b8da6a
  return (
    <div className="rounded-lg my-8 bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px max-w-3xl mx-auto">
      {Content.subjects.map((subject, actionIdx) => (
        <div
          key={subject.subject}
          className={`
            ${
              actionIdx === 0
                ? "w-full rounded-tl-lg rounded-tr-lg sm:rounded-tr-none "
                : ""
            }
            ${actionIdx === 1 ? "sm:rounded-tr-lg " : ""}
            ${
              actionIdx === Content.subjects.length - 2
                ? "sm:rounded-bl-lg "
                : ""
            }
            ${
              actionIdx === Content.subjects.length - 1
                ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none "
                : ""
            }
            relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-secondary-500
          `}
        >
          <div>
            <Img src={subject.icon} alt={subject.subject} />
          </div>
          <div className="mt-8">
            <h3 className="text-lg font-medium">
              <Link to={subject.link} className="focus:outline-none">
                {/* Extend touch target to entire panel */}
                <span className="absolute inset-0" aria-hidden="true" />
                {subject.subject}
              </Link>
            </h3>
          </div>
          <span
            className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
            aria-hidden="true"
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </span>
        </div>
      ))}
    </div>
  )
}

const MathResources = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.mathResources}
      />
      <div className="sm:flex max-w-7xl mx-auto">
        <MathResourceDrawer />
        <div className="flex-grow">
          <Header />
          <SubjectsSection />
          <CTA title={Content.cta} actions={Content.ctaActions} />
        </div>
      </div>
    </Layout>
  )
}

export default MathResources
